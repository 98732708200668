import router from "@/router";

const baseURL = window.location.origin == "http://localhost:8080" ? "http://localhost:8000/api/auth/" : window.location.origin + "/api/auth/";
const headers = {"content-type":"application/json; charset=UTF-8"};

const state = {
    user: null,
    token: null,
    user_role: null
}

const getters = {
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
    StateToken: state => state.token,
    StateUserRole: state => state.user_role
}

const actions = {

        async LogIn({dispatch, commit}, user)
        {   
            const data = {
                headers: headers,
                body: JSON.stringify(user),
                method: "POST"
            }
            let response = await fetch(`${baseURL}login`,data)
            
            if (response.status == 200)
            {
                let data = await response.json();
                await commit("setToken", data.token)
                await dispatch("viewMe");
                return true;
            }
            return false
            
        },

        async viewMe({commit})
        {
            let res = await fetch(`${baseURL}me`, {method:'GET', headers: {"Authorization": state.token}});
            if (res.status == 200)
            {
                let data = await res.json();
                await commit("setUser", data)
            }
            else 
            {
                alert("No valid data")
                router.push("/login")
            }
        },

        async logOut({commit})
        {
            await fetch(`${baseURL}logout`);
            commit("logout");
            router.push("/login")
        }
}

const mutations = {
    setUser(state, user)
    {
        state.user = user
        state.user_role = user.roles
    },
    setToken(state, token)
    {
        state.token = token
    },
    logout (state)
    {
        state.user = null
        state.user_role = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}