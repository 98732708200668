<template>
    <h3 style="text-align: left;">Add User</h3>
    <b></b>
    <div class="row">
        <div class="col-md-10 col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form style="text-align: left;" @submit.prevent="submit">
                        <div class="row">
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>First Name</label>
                                <input type="text" class="form-control" placeholder="First Name"  v-model="form.firstname">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Middle Name</label>
                                <input type="text" class="form-control" placeholder="Middle Name"  v-model="form.middlename">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Last Name</label>
                                <input type="text" class="form-control" placeholder="Last Name"  v-model="form.lastname">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Username</label>
                                <input type="text" class="form-control" placeholder="Username"  v-model="form.username">
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label>Email address</label>
                                <input type="email" class="form-control" placeholder="Enter email"  v-model="form.email">
                                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12" v-if="user_role =='superadmin'">
                                <label>Role</label>
                                <select class="form-control" v-model="form.roles">
                                    <option>superadmin</option>
                                    <option>admin</option>
                                    <option>user</option>
                                </select>
                            </div>
                            <div class="form-group mb-3 col-md-6 col-sm-12">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" class="form-control" placeholder="Password"  v-model="form.password">
                            </div>
                        </div>
                        <br>
                        <button type="submit" class="btn btn-primary"> Save </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions} from "vuex";

export default {
    data() {
        return {
            form: {
                email: "",
                username: "",
                firstname: "",
                middlename: "",
                lastname: "",
                password: "",
                roles: "user"
            }
        }
    },
    computed: {
        ...mapGetters({user_role: "StateUserRole"})
    },
    methods: {
        ...mapActions(["AddUser"]),
        async submit() {
            this.AddUser(this.form)
                .then(response => {
                    if (response == true)
                    {
                        this.$router.push("/users")
                    }
                })
        }
    },
    mounted(){
        
    }
}
</script>