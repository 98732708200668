<template>
  <RouterView></RouterView>
</template>

<script>

</script>

<style>
/* html{
  margin:0;
  padding:0;
  height:100%;
}

body
{
  min-height: 100% !important;
  max-height: 100% !important; */
  /* background-color: #0a2e38 !important; */
/* }  */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
