import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate';
import auth from "./modules/auth";
import users from "./modules/users"

const store = new Vuex.Store(
    {
        modules: {
            auth,
            users
        }
    }
)

export default store;