<template>
    <div class="container p-5">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 col-sm-12">
                <div class="card p-5">
                    <h3>Login</h3>
                    <form @submit.prevent="submit">
                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="email" v-model="form.email" class="form-control"  placeholder="Email Address"/>
                        </div>

                        <!-- Password input -->
                        <div class="form-outline mb-4">
                            <input type="password" v-model="form.password" class="form-control"  placeholder="Password"/>
                        </div>

                        <!-- Submit button -->
                        <button type="submit" class="btn btn-primary btn-block m-2">Login</button> 

                    </form>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            form : {
                email: "",
                password: ""
            }
        }
    },
    methods: {
        ...mapActions(["LogIn"]),
        async submit(){
            this.LogIn(this.form)
            .then(response => {
                if (response == true)
                {
                    this.$router.push('/');
                }
            })
            
        }
    },
}
</script>