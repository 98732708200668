import { createRouter, createWebHashHistory } from "vue-router";
import AdminPage from "@/views/AdminPage.vue";
import UserPage from "@/views/Users/UserPage.vue";
import AddUserPage from "@/views/Users/AddUserPage.vue";
import EditUserPage from "@/views/Users/EditUserPage.vue";
import LoginPage from "@/views/LoginPage.vue";



const routes = [
    {
        name:"Home",
        psth:"/",
        component: AdminPage,

        children:[
            {
                name:"User",
                path:"/users",
                component: UserPage
            },
            {
                name: "AddUser",
                path:"/add_user",
                component: AddUserPage
            },
            {
                name: "EditUser",
                path:"/edit_user",
                component: EditUserPage
            }
        ]
    },
    {
        name:"Login",
        path: "/login",
        component: LoginPage
    }
]

const router = createRouter(
    {
        history: createWebHashHistory(),
        routes
    }
);

export default router;